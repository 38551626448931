var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-card',[_c('v-card-title',[_vm._v(" Facturaciones en el sistema "),_c('v-spacer'),_c('div',{staticClass:"mx-4"},[_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-menu',{ref:"menu1",staticStyle:{"border-bottom":"1px solid #000"},attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dense":"","clearable":"","placeholder":"Todos","solo":"","readonly":""},on:{"click:clear":_vm.limpiarRango},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"id":"datepicker-starkoms","range":""},on:{"input":_vm.formatearRango},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-text-field',{staticClass:"mr-2",attrs:{"clearable":"","label":"Búsqueda","hide-details":"","dense":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary mx-2",staticStyle:{"min-width":"138px"},attrs:{"dark":""},on:{"click":_vm.fetchDataPaginated}},[_vm._v("Buscar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.search || !!_vm.dateRangeText),expression:"!!search || !!dateRangeText"}],staticClass:"mx-2",attrs:{"dark":"","color":"grey"},on:{"click":_vm.limpiarFiltros}},[_vm._v("Limpiar Filtros")])],1)])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"single-select":true,"show-select":"","no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","sort-by":'id',"sort-desc":true},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.customer.email)+" ")])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"})]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"small":"","color":"success","dark":"","href":item.pdf,"target":"_blank"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-file")]),_vm._v(" Ver PDF ")],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }