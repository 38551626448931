<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Facturaciones en el sistema
                        <v-spacer></v-spacer>
                        <div class="mx-4">
                            <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-card outlined tile elevation="1">
                            <v-card-text style="padding-bottom: 0px;">
                                <div class="d-flex justify-space-between">
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px" style="border-bottom: 1px solid #000;"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="mr-2" dense v-bind="attrs" v-on="on" clearable @click:clear="limpiarRango" v-model="dateRangeText" placeholder="Todos" solo readonly ></v-text-field>
                                        </template>
                                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates" ></v-date-picker>
                                    </v-menu>
                                    <v-text-field
                                    v-model="search"
                                    clearable
                                    label="Búsqueda"
                                    hide-details
                                    dense
                                    solo
                                    class="mr-2"
                                    ></v-text-field>
                                    <v-btn class="btn-starkoms-primary mx-2" dark style="min-width: 138px;" @click="fetchDataPaginated" >Buscar</v-btn>
                                    <v-btn
                                            v-show="!!search || !!dateRangeText"
                                            @click="limpiarFiltros"
                                            class="mx-2" dark color="grey"
                                        >Limpiar Filtros</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="tableData"
                            :search="search"
                            :single-select="true"
                            show-select
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            :sort-by="'id'"
                            :sort-desc="true"
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.email="{ item }">
                                <td class="text-center">
                                    {{item.customer.email}}
                                </td>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <td class="text-center">
                                    <!--
                                    <b-button size="sm" v-if="item.name_order_state == 'Ingresado'" pill variant="success">Ingresado</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Preparando'" pill variant="info">Preparando</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Listo para enviar'" pill variant="info">Listo para enviar</b-button>                               
                                    <b-button size="sm" v-if="item.name_order_state == 'Entregado a courier'" pill variant="info">Entregado a courier</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'En tránsito'" pill variant="info">En tránsito</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Entregado'" pill variant="info">Entregado</b-button>
                                    -->
                                </td>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                                <td>
                                    <!--
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                class="mr-2"
                                                v-on="on"
                                                @click="$router.push({name: 'envio', params: {envio: item.id}})"
                                            >
                                                mdi-arrow-right-box
                                            </v-icon>
                                        </template>
                                        <span>Ver detalles</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                class="mr-2"
                                                v-on="on"
                                                @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})"
                                            >
                                                mdi-cart-arrow-right
                                            </v-icon>
                                        </template>
                                        <span>Ver orden</span>
                                    </v-tooltip>
                                    -->
                                    <v-btn
                                        small
                                        color="success" dark
                                        :href=item.pdf
                                        target="_blank"
                                    >
                                        <v-icon left dark>mdi-file</v-icon>
                                        Ver PDF
                                    </v-btn>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data () {
        return {
            search: '',
            selected: [],
            tableData: [],
            dates: [],
            menu1: false,
            dateRangeText: '',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Facturaciones", route: "/facturaciones" },
        ]);
        this.fetchData();
    },
    methods: {
        limpiarFiltros() {
            this.search = "";
            this.dateRangeText = "";
            this.fetchData();
        },
        fetchData()
        {
            var vm = this;
            this.axios({
                url: 'invoices/json', 
                method: 'GET',
                params: {
                    "pagination[page]": "1",
                    "pagination[pages]": "24",
                    "pagination[perpage]": "10",
                    "pagination[total]": "234",
                    "sort[sort]": "asc",
                    "sort[field]": "id",
                    "query": ""
                }
            }).then( response => {
                vm.tableData = response.data.data;
            }).catch( error => {
                console.log(error);
            })
        },
        filtroRangoFechas(value, search, item){
            if( !this.dateRangeText || !this.dates.length ){
                return true;
            }else{
                if( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1])) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatearRango()
        {
            if(!this.dates.length){
                this.dateRangeText = '';
            }else{
                this.dateRangeText = this.dates.join(' ~ ');
            }
        },
        limpiarRango()
        {
            this.dates = [];
        }
    },
    computed: {
        headers(){ 
            return [
                { text: '#...',  align: 'left',  sortable: true, value: 'id', },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Invoice number', value: 'invoice_number' },
                { text: 'Email', value: 'email'},
                { text: 'Fecha', value: 'updated_at', filter: v => this['filtroRangoFechas'](v) },
                { text: 'Estado', value: 'current_state' },
                { text: 'Actions', value: 'acciones', sortable: false },
            ];
        }
    }
}
</script>